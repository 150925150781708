import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import PageIllustration from '../../components/PageIllustration'
import Footer from '../../components/Footer'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false, isBanner: false }
  }

  componentDidMount() {
    let classNames = document.documentElement.classList;

    if (classNames.contains("banner")){
      this.setState({isBanner: true})
    }
    else {
      this.setState({isBanner: false})
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
      <Header />

           
        <div className="flex flex-col min-h-screen overflow-hidden">
        {/* Page header */}
        {/* <PageIllustration /> */}
{/*  Site header */}

{/*  Page content */}
<main className="flex-grow">
  
  <section className="relative">
      <PageIllustration />

          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className={`pt-32 pb-12 md:pt-40 md:pb-20 ${this.state.isBanner ? "mt-20 md:mt-8": ""}`}>

              {/* Page header */}
              <div className="max-w-5xl md:max-w-3xl mx-auto text-center pb-12 md:pb-16 w-5/6 md:w-3/6">
                <h1 className="h1 mb-4 text-4xl lg:text-5xl text-gray-800 dark:text-gray-300 font-extrabold leading-tight tracking-tighter" 
                data-aos="zoom-y-out">Contact</h1>
                <p className="text-xl text-gray-400 " data-aos="zoom-y-out" data-aos-delay="200">If you'd like to get in touch, simply enter your message below and 
              we'll get back to you shortly. 
                </p>
              </div>

              {/* Contact form */}
              <form className="max-w-xl mx-auto"
                              name="contact"
                              method="post"
                              action="/contact/thanks/"
                              data-netlify="true"
                              data-netlify-honeypot="bot-field"
                              onSubmit={this.handleSubmit}
                              data-aos="zoom-y-out" data-aos-delay="150">
              <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="name">Name <span className="text-red-600">*</span></label>
                    <input id="name" type="name" 
                    className="form-input py-3 px-4 rounded-sm placeholder-gray-500 w-full text-gray-800 dark:text-gray-300 bg-transparent border 
                    dark:border-gray-700 focus:border-gray-500" placeholder="Enter your name" required
                    type={'text'}
                    name={'name'}
                    onChange={this.handleChange}
                    required={true} />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                    <input id="email" type="text" className="form-input py-3 px-4 rounded-sm placeholder-gray-500 w-full 
                    text-gray-800 dark:text-gray-300 bg-transparent border dark:border-gray-700 focus:border-gray-500" placeholder="Enter your email address" required 
                    type={'email'}
                    name={'email'}
                    onChange={this.handleChange}
                    id={'email'}
                    required={true}/>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1" htmlFor="message">Message</label>
                    <textarea id="message" rows="4" className="form-textarea py-3 px-4 rounded-sm placeholder-gray-500 w-full 
                    text-gray-800 dark:text-gray-300 bg-transparent border dark:border-gray-700 focus:border-gray-500" placeholder="Write your message"
                    name={'message'}
                    onChange={this.handleChange}
                    id={'message'}
                    required={true}
                    ></textarea>
                  </div>
                </div>
                {/* <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-gray-400 ml-2">I agree to the privacy policy</span>
                    </label>
                  </div>
                </div> */}
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full
                    px-4 py-2 font-medium inline-flex items-center justify-center border border-transparent rounded-sm leading-snug transition duration-150 ease-in-out text-white bg-purple-600 hover:bg-purple-700" type="submit">Send</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </section>



</main>

</div>

      <Footer />
      </Layout>
    )
  }
}
